import React, { useEffect, useRef, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Header from '../Header';
import Footer from '../Footer';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { useParams } from 'react-router-dom';
import { API_URL, BASE_URL } from '../Config';
import jsPDF from 'jspdf';  // Library to generate PDF
import 'jspdf-autotable';  // Plugin for auto table
import generateOrderSummaryPDF from './generateOrderSummaryPDF';

const OrderSummaryPrint = (props) => {

	const { orderId } = useParams();
	const [shopDetails, setShopDetails] = useState([]);
	const [orderDetails, setOrderDetails] = useState([]);
	const [productDetails, setProductDetails] = useState([]);
	const [totalVal, setTotalVal] = useState(
  {
   totalstd: 0,
   totalzeroGoods: 0,
   totalTax: 0,
   orderDiscount: 0,
   grandTotal: 0,
 }
 );

	const accessToken = localStorage.getItem('accessToken');

	const fetchInvoice = async () => {
   const formData = new FormData();
   formData.append('orderId', orderId);
   const apiUrl = `${API_URL}/ws-order-history-details`;
   try {
     const response = await fetch(apiUrl, {
       method: 'POST',
       headers: {
         authorization: `Bearer ${accessToken}`,
       },
       body: formData,
     });

     if (!response.ok) {
       throw new Error('Failed to fetch Not Order Report');
     }
     const data = await response.json();
	      //console.log(data.details.shop_details);
	      setShopDetails(data.details.shop_details);
	      setOrderDetails(data.details.orderDetails[0]);
	      setProductDetails(data.details.product_details);
	      
	      /*const totalstd = data.details.product_details
       .filter(item => item.item_tax != '0.00' && item.item_tax != null)
       .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

       const totalzeroGoods = data.details.product_details
       .filter(item => item.item_tax == '0.00' || item.item_tax === null)
       .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);*/

	      // Set the total values
	      /*setTotalVal({
         totalstd,
         totalzeroGoods,
         totalTax: parseFloat(data.details.orderDetails[0].total_tax),
         orderDiscount: parseFloat(data.details.orderDetails[0].order_discount),
         orderDiscount1: parseFloat(data.details.orderDetails[0].order_discount1),
         grandTotal: parseFloat(data.details.orderDetails[0].grand_total),
       });*/

       // Calculate total standard goods, zero-rated goods, tax, and other totals
        const totalstd = data.details.product_details
            .filter(item => item.item_tax != '0.00' && item.item_tax != null)
            .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

        const totalzeroGoods = data.details.product_details
            .filter(item => item.item_tax == '0.00' || item.item_tax === null)
            .reduce((sum, item) => sum + (parseFloat(item.unit_price) * parseFloat(item.order_qty)), 0);

        // Calculate total tax
        const totalTax = data.details.product_details
            .reduce((sum, item) => sum + (item.item_tax ? parseFloat(item.item_tax) : 0), 0);

        // Calculate order discounts
        const orderDiscount = parseFloat(data.details.orderDetails[0].order_discount) || 0;
        const orderDiscount1 = parseFloat(data.details.orderDetails[0].order_discount1) || 0;

        // Calculate grand total
        const grandTotal = totalstd + totalzeroGoods + totalTax - orderDiscount - (totalstd * orderDiscount1 / 100);

        

        setTotalVal({
            totalstd,
            totalzeroGoods,
            totalTax,
            orderDiscount,
            orderDiscount1,
            grandTotal
        });

	      
	    } catch (error) {
       console.error('api fail Error fetching Not Order Report:', error);
     }
   };

   useEffect(() => {
     if (accessToken === null) {
       window.location.href = '/login';
       return;
     }
     fetchInvoice();
   },[]);

   useEffect(() => {
    const table = $('#invoicePrint').DataTable({
      pageLength: 50,
      paging: false,
      lengthMenu: [10, 20, 50],
      searching: false,
      ordering: false,
      info: false,
      responsive: true,
      autoWidth: false,
      data: productDetails,
      columns: [
      { 
        data: null,
        render: function (data, type, row) { 
          return `<span>${row.product_code} - ${row.product_name}</span> `;
        }
      },
      { 
        data: null, 
        className : 'text-center',
        render : function(data, type, row){
          return `<span>${parseInt(row.order_qty)}</span>`;
        } 
      },
      { 
        data: null, 
        className : 'text-center',
        render : function(data, type, row){
          return `<span>${row.order_type}</span>`;
        } 
      },
      { 
        data: 'unit_price',
        className : 'text-end',
        render: function (data, type, row) { 
         return row.unit_price ? `<span>£ ${row.unit_price}</span>` : `<span>£ 0.00</span>`;
       }
     },
     { 
      data: 'item_tax',
      className : 'text-end',
      render: function (data, type, row) { 
        return row.item_tax ? `<span>£ ${row.item_tax}</span>` : `<span>0.00</span>`;
      }
    },
    { 
      data: 'subtotal',
      className : 'text-end',
      render: function (data, type, row) { 
      return row.subtotal ? `<span>£ ${parseFloat(row.unit_price * row.order_qty).toFixed(2)}</span>` : `<span>£ 0.00</span>`;
     }
   },
   
   ],
   columnDefs: [
   {
    targets: [0], 
    orderable: false
  }
  ],
  drawCallback: function (settings) {
    var api = this.api();	
    $('tfoot').empty();
    
	      // Create footer rows with totals
        const footerRows = [
        {
          colSpan: 5,
          label: 'Total Std Goods',
          value: `£${totalVal.totalstd.toFixed(2)}`
        },
        {
          colSpan: 5,
          label: 'VAT Amount',
          value: `£${totalVal.totalTax.toFixed(2)}`
        },
        {
          colSpan: 5,
          label: 'Total Zero Goods',
          value: `£${totalVal.totalzeroGoods.toFixed(2)}`
        },
        {
          colSpan: 5,
          label: 'Promotion Discount',
          value: `£${totalVal.orderDiscount.toFixed(2)}`
        },
        {
          colSpan: 5,
          label: 'Order Discount(%)',
  value: `£${totalVal && totalVal.orderDiscount1 ? totalVal.orderDiscount1.toFixed(2) : '0.00'}` // Check for totalVal and orderDiscount1
},
{
  colSpan: 5,
  label: 'Invoice Total',
  value: `£${(totalVal && totalVal.grandTotal && totalVal.orderDiscount1 !== undefined)
    ? (totalVal.grandTotal - (totalVal.grandTotal * (totalVal.orderDiscount1 / 100))).toFixed(2)
    : '0.00'}` // Check for grandTotal and orderDiscount1
  }
  ];
  
        // Append rows to the footer
        footerRows.forEach(row => {
          const footerRow = $('<tr>')
          .append(`<td colSpan="${row.colSpan}"><span class="right">${row.label}</span></td>`)
          .append(`<td><span>${row.value}</span></td>`);
          $('tfoot').append(footerRow);
        });
      }
    });
    return () => {
      table.destroy();
    };
  }, [productDetails, totalVal]);

   /*const generatePDF = () => {
    const doc = new jsPDF();

    // Header
    const header = [
    [`Order Summary for ${orderDetails.company}`, `Order Date: ${orderDetails.date}`],
    [`Order : INV${orderDetails.reference_no}`, `Status: ${orderDetails.sale_status}`],
    ];

    // Footer
    const footer = [
    [`Total Std Goods: £${totalVal.totalstd.toFixed(2)}`],
    [`VAT Amount: £${totalVal.totalTax.toFixed(2)}`],
    [`Total Zero Goods: £${totalVal.totalzeroGoods.toFixed(2)}`],
    [`Order Discount: £${totalVal.orderDiscount.toFixed(2)}`],
    [`Invoice Total: £${totalVal.grandTotal.toFixed(2)}`],
    ];

    // Table Columns
    const columns = [
    { title: 'Description', dataKey: 'description' },
    { title: 'Quantity', dataKey: 'quantity' },     
    { title: 'Unit Price', dataKey: 'unit_price' },
    { title: 'VAT Amount', dataKey: 'item_tax' },
    { title: 'Net Amount', dataKey: 'subtotal' },
    ];

    // Add header
    

    // Add table
    const itemsPerPage = 20;
    const totalPages = Math.ceil(productDetails.length / itemsPerPage);

    for (let i = 0; i < totalPages; i++) {
      const startIndex = i * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const pageData = productDetails.slice(startIndex, endIndex);

      doc.autoTable({
      	head: header,
      	startY: 10,
       headStyles: { fontSize: 10, textColor: '#666', fillColor: '#eee' },
     });

      doc.autoTable({
        head: [columns.map(col => col.title)],
        body: pageData.map(item => [
          `${item.product_code} - ${item.product_name}`,
          item.order_qty,
          `£ ${item.unit_price}`,
          item.item_tax || '0.00',
          `£ ${parseFloat(item.unit_price * item.order_qty).toFixed(2)}`,
          ]),
        startY: 30,
        headStyles: { fontSize: 10, textColor: '#fff', fillColor: '#e28483' }, 
        theme: 'grid',
      });

      // Add footer only on the last page
      if (i === totalPages - 1) {
      	const table = doc.autoTable.previous;
        const endY = table.finalY;

        doc.autoTable({
          startY: endY + 10,
          body: footer.map(row => [row[0]]),
          styles: { fontSize: 10 },
        });
      }
      // Add page break if not last page
      if (i < totalPages - 1) {
        doc.addPage();
      }


    }

   // doc.save('OrderSummary.pdf');
   doc.save(`invoice_${orderDetails.reference_no}.pdf`);
 };*/

 

 const newPdtArray = [
 {
  line_no: 5,
  sku_code: 'dwedi25',
  pdt_desc: 'afdafd',
  pack: '4',
  size: '23',
  unit_brcode: 'dew1de5',
  case_qty: '15',
  unit_price: '758',
  amount: '472',
  vat_rate: '5%',
  vat_amount: '452',
  total: '2514'
},
{
  line_no: 5,
  sku_code: 'dwedi25',
  pdt_desc: 'afdafd',
  pack: '4',
  size: '23',
  unit_brcode: 'dew1de5',
  case_qty: '15',
  unit_price: '758',
  amount: '472',
  vat_rate: '5%',
  vat_amount: '452',
  total: '2514'
}
];

/*const newGeneratePDF = () => {
  const doc = new jsPDF('landscape');
  const pageWidth = doc.internal.pageSize.getWidth();

  // Header Sections with Different Formatting
  const headerSections1 = [
    [
      { text: 'Star Pacific UK LTD', size: 12, bold: false },
      { text: 'Unit 6 Abenglen Industrial Estate,', size: 8, bold: false },
      { text: 'Betam Road, Hayes', size: 8, bold: false },
      { text: 'Middlesex, London UB3 1AT', size: 8, bold: false },
      { text: 'United Kingdom', size: 8, bold: false },
    ],
    [
      { text: 'Co. Reg. No. : 7298396', size: 8, bold: true },
      { text: 'VAT Reg. No.: GB994533184', size: 8, bold: false },
      { text: 'Tel: 44-208 329 3800', size: 8, bold: false },
    ],
    [
      { text: '', size: 8, bold: false },
    ],
    [
      { text: 'TAX INVOICE', size: 12, bold: false }
    ]
  ];

  const headerSections2 = [
    [
      { text: 'Invoice Address: ALLIANCE INTERNATIONAL LTD', size: 8, bold: false },
      { text: 'UNIT 6 ABENGLEN INDUSTRIAL', size: 8, bold: false },
      { text: 'ESTATE', size: 8, bold: false },
      { text: 'MIDDLESEX', size: 8, bold: false },
      { text: 'HAYES UB3 1SS', size: 8, bold: false },
      { text: 'United Kingdom', size: 8, bold: false }
    ],
    [
      { text: 'VAT Reg. No.: ', size: 8, bold: true },
      { text: '300583441', size: 8, bold: false },
      { text: 'Rep: RAJ SINGH', size: 8, bold: false },
      { text: 'Account No: ALI001', size: 8, bold: false }
    ],
    [
      { text: 'Tax Invoice No. & Date:', size: 8, bold: false },
      { text: ':3900389418 06.07.2021', size: 8, bold: false },
      { text: '', size: 8, bold: false },
      { text: 'Jon Ref: N5424', size: 8, bold: false }
    ],
    [
      { text: 'Currency : GBP', size: 8, bold: false },
      { text: 'Customer Ref: 05148562', size: 8, bold: false },
      { text: 'Booking Ref: 5258463', size: 8, bold: false },
      { text: 'Delivery Date: 20/08/2024', size: 8, bold: false },
      { text: 'SO#:', size: 8, bold: false }
    ]
  ];

  const headerSections3 = [
    [
      { text: 'Delivery Address: ALLIANCE INTERNATIONAL LTD', size: 8, bold: false },
      { text: 'UNIT 6 ABENGLEN INDUSTRIAL,', size: 8, bold: false },
      { text: 'ESTATE', size: 8, bold: false },
      { text: 'MIDDLESEX', size: 8, bold: false },
      { text: 'HAYES UB3 1SS', size: 8, bold: false },
      { text: 'United Kingdom', size: 8, bold: false },
    ],
    [
      { text: '', size: 8, bold: true },
    ],
    [
      { text: 'Payment Terms:', size: 8, bold: false },
      { text: '14 Days from Date of Invoice', size: 8, bold: false },
      { text: '', size: 8, bold: false },
      { text: 'Due Date: 15.08.2021', size: 8, bold: false },
    ],
    [
      { text: '', size: 8, bold: false }
    ]
  ];

  const sectionWidth = pageWidth / 4;
  let y = 10; // Starting y position

  // Add Header Sections 1
  headerSections1.forEach((section, index) => {
    const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, y + i * 5); // Reduced line height to 6
    });

    if (index % 4 === 3) { // Move to the next row after every four columns
      y += 20; // Increase y position for new row
    }
  });

  y += 7; // Add space between header sections

  // Add Header Sections 2
  headerSections2.forEach((section, index) => {
    const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, y + i * 5); // Reduced line height to 6
    });

    if (index % 4 === 3) { // Move to the next row after every four columns
      y += 20; // Increase y position for new row
    }
  });

  y += 15; // Add space between header sections

  // Add Header Sections 3
  headerSections3.forEach((section, index) => {
    const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, y + i * 5); // Reduced line height to 6
    });

    if (index % 4 === 3) { // Move to the next row after every four columns
      y += 20; // Increase y position for new row
    }
  });

  y += 10; // Add space before the table

  // Table Columns
  const columns = [
    { title: 'Line No', dataKey: 'line_no' },
    { title: 'SKU Code', dataKey: 'sku_code' },
    { title: 'Product Description', dataKey: 'pdt_desc' },
    { title: 'Pack', dataKey: 'pack' },
    { title: 'Size', dataKey: 'size' },
    { title: 'Unit Brcode', dataKey: 'unit_brcode' },
    { title: 'Case Qty', dataKey: 'case_qty' },
    { title: 'Unit Price', dataKey: 'unit_price' },
    { title: 'Amount', dataKey: 'amount' },
    { title: 'Vat Rate(%)', dataKey: 'vat_rate' },
    { title: 'Vat Amount', dataKey: 'vat_amount' },
    { title: 'Total Amount', dataKey: 'total' }
  ];

  // Add Table
  doc.autoTable({
    startY: y,
    head: [columns.map(col => col.title)],
    body: newPdtArray.map(item => [
      item.line_no,
      item.sku_code,
      item.pdt_desc,
      item.pack,
      item.size,
      item.unit_brcode,
      item.case_qty,
      item.unit_price,
      item.amount,
      item.vat_rate,
      item.vat_amount,
      item.total
    ]),
    theme: 'grid',
    headStyles: { fillColor: '#e28483', textColor: '#fff' },
    margin: { top: 10 }
  });

  // Save PDF
  doc.save('invoice_1.pdf');
};
*/

  /*const footerSections1 = [
  [
    { text: 'Notes: Delivered on 15/08; N5267', size: 8, bold: false },
    { text: 'Ref: VBG 5KG; Transportify', size: 8, bold: false },
    { text: '', size: 8, bold: false },
    { text: 'Beneficiary Bank Details:', size: 8, bold: false },
    { text: 'Bank Name : HSBC', size: 8, bold: false },
    { text: 'Bank Acc. # : 260183041180', size: 8, bold: false },
    { text: 'Swift Code : HSBCSGSG', size: 8, bold: false },
    { text: 'Bank Address : 21 COLLYER QUAY #01-01 HSBC BLDG SINGAPORE', size: 8, bold: false }
  ],
  [
    { text: '', size: 8, bold: true },
  ],
  [
    { text: '', size: 8, bold: false },
  ],
  [
    { text: 'Gross Weight: 25,00 KGS', size: 8, bold: false },
    { text: 'CBM: 50M3', size: 8, bold: false },
    { text: 'Net weight: 15,000 KGS', size: 8, bold: false },
  ]
];

const footerSections2 = [
  [
    { text: 'Subject to Star Pacific Limited terms and conditions of sale, a copy of which is available on request', size: 8, bold: false },
    { text: 'Star Pacific UK LTD (No. 7298396)', size: 8, bold: false },
    { text: 'Reservation of Title. The property in and the title to all goods sold by Star Pacific UK LTD shall not pass to the buyer until all monies due and owing have been paid in full', size: 8, bold: false },
  ]
];

const newGeneratePDF = () => {
  const doc = new jsPDF('landscape');
  const pageWidth = doc.internal.pageSize.getWidth();

  // Header Sections with Different Formatting
  const headerSections1 = [
    [
      { text: 'Star Pacific UK LTD', size: 12, bold: false },
      { text: 'Unit 6 Abenglen Industrial Estate,', size: 8, bold: false },
      { text: 'Betam Road, Hayes', size: 8, bold: false },
      { text: 'Middlesex, London UB3 1AT', size: 8, bold: false },
      { text: 'United Kingdom', size: 8, bold: false },
    ],
    [
      { text: 'Co. Reg. No. : 7298396', size: 8, bold: true },
      { text: 'VAT Reg. No.: GB994533184', size: 8, bold: false },
      { text: 'Tel: 44-208 329 3800', size: 8, bold: false },
    ],
    [
      { text: '', size: 8, bold: false },
    ],
    [
      { text: 'TAX INVOICE', size: 12, bold: false }
    ]
  ];

  const headerSections2 = [
    [
      { text: 'Invoice Address: ALLIANCE INTERNATIONAL LTD', size: 8, bold: false },
      { text: 'UNIT 6 ABENGLEN INDUSTRIAL', size: 8, bold: false },
      { text: 'ESTATE', size: 8, bold: false },
      { text: 'MIDDLESEX', size: 8, bold: false },
      { text: 'HAYES UB3 1SS', size: 8, bold: false },
      { text: 'United Kingdom', size: 8, bold: false }
    ],
    [
      { text: 'VAT Reg. No.: ', size: 8, bold: true },
      { text: '300583441', size: 8, bold: false },
      { text: 'Rep: RAJ SINGH', size: 8, bold: false },
      { text: 'Account No: ALI001', size: 8, bold: false }
    ],
    [
      { text: 'Tax Invoice No. & Date:', size: 8, bold: false },
      { text: ':3900389418 06.07.2021', size: 8, bold: false },
      { text: '', size: 8, bold: false },
      { text: 'Jon Ref: N5424', size: 8, bold: false }
    ],
    [
      { text: 'Currency : GBP', size: 8, bold: false },
      { text: 'Customer Ref: 05148562', size: 8, bold: false },
      { text: 'Booking Ref: 5258463', size: 8, bold: false },
      { text: 'Delivery Date: 20/08/2024', size: 8, bold: false },
      { text: 'SO#:', size: 8, bold: false }
    ]
  ];

  const headerSections3 = [
    [
      { text: 'Delivery Address: ALLIANCE INTERNATIONAL LTD', size: 8, bold: false },
      { text: 'UNIT 6 ABENGLEN INDUSTRIAL,', size: 8, bold: false },
      { text: 'ESTATE', size: 8, bold: false },
      { text: 'MIDDLESEX', size: 8, bold: false },
      { text: 'HAYES UB3 1SS', size: 8, bold: false },
      { text: 'United Kingdom', size: 8, bold: false },
    ],
    [
      { text: '', size: 8, bold: true },
    ],
    [
      { text: 'Payment Terms:', size: 8, bold: false },
      { text: '14 Days from Date of Invoice', size: 8, bold: false },
      { text: '', size: 8, bold: false },
      { text: 'Due Date: 15.08.2021', size: 8, bold: false },
    ],
    [
      { text: '', size: 8, bold: false }
    ]
  ];

  const sectionWidth = pageWidth / 4;
  let y = 10; // Starting y position

  // Add Header Sections 1
  headerSections1.forEach((section, index) => {
    const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, y + i * 5); // Reduced line height to 6
    });

    if (index % 4 === 3) { // Move to the next row after every four columns
      y += 20; // Increase y position for new row
    }
  });

  y += 7; // Add space between header sections

  // Add Header Sections 2
  headerSections2.forEach((section, index) => {
    const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, y + i * 5); // Reduced line height to 6
    });

    if (index % 4 === 3) { // Move to the next row after every four columns
      y += 20; // Increase y position for new row
    }
  });

  y += 15; // Add space between header sections

  // Add Header Sections 3
  headerSections3.forEach((section, index) => {
    const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, y + i * 5); // Reduced line height to 6
    });

    if (index % 4 === 3) { // Move to the next row after every four columns
      y += 20; // Increase y position for new row
    }
  });

  y += 10; // Add space before the table

  // Table Columns
  const columns = [
    { title: 'Line No', dataKey: 'line_no' },
    { title: 'SKU Code', dataKey: 'sku_code' },
    { title: 'Product Description', dataKey: 'pdt_desc' },
    { title: 'Pack', dataKey: 'pack' },
    { title: 'Size', dataKey: 'size' },
    { title: 'Unit Brcode', dataKey: 'unit_brcode' },
    { title: 'Case Qty', dataKey: 'case_qty' },
    { title: 'Unit Price', dataKey: 'unit_price' },
    { title: 'Amount', dataKey: 'amount' },
    { title: 'Vat Rate(%)', dataKey: 'vat_rate' },
    { title: 'Vat Amount', dataKey: 'vat_amount' },
    { title: 'Total Amount', dataKey: 'total' }
  ];

  // Add Table
  doc.autoTable({
    startY: y,
    head: [columns.map(col => col.title)],
    body: newPdtArray.map(item => [
      item.line_no,
      item.sku_code,
      item.pdt_desc,
      item.pack,
      item.size,
      item.unit_brcode,
      item.case_qty,
      item.unit_price,
      item.amount,
      item.vat_rate,
      item.vat_amount,
      item.total
    ]),
    theme: 'grid',
    headStyles: { fillColor: '#e28483', textColor: '#fff' },
    margin: { top: 10 }
  });

  // Get the position where the table ended
  const finalY = doc.autoTable.previous.finalY;

  // Add Footer Sections 1
  y = finalY + 10; // Space after table
  footerSections1.forEach((section, index) => {
    const colStartX = (index % 4) * sectionWidth + 10; // Calculate X position for each column

    section.forEach((item, i) => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, colStartX, y + i * 6); // Reduced line height to 6
    });

    if (index % 4 === 3) { // Move to the next row after every four columns
      y += 20; // Increase y position for new row
    }
  });

  // Add Footer Sections 2
  y = doc.internal.pageSize.height - 30; // Position 30 units from the bottom
  const centerX = pageWidth / 2; // Center X position

  footerSections2.forEach(section => {
    section.forEach(item => {
      doc.setFontSize(item.size);
      doc.setFont(item.bold ? 'helvetica' : 'helvetica', item.bold ? 'bold' : 'normal');
      doc.text(item.text, centerX, y, { align: 'center' }); // Center text
      y += 10; // Add space between lines in the footer
    });
  });

  // Save PDF
  doc.save('invoice_1.pdf');
};*/


 const handleGenerateDeliveryNotePDF = (e, orderId) => {
      generateOrderSummaryPDF(orderId);
  }
return(
  <>
  <Header />
  <div className="breadcrumbs">
  <div className="container-fluid">
  <div className="row">
  <div className="col-12 col-lg-12 col-sm-12"> 
  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
  
  <span className="last-crumb">Order Summary </span> 
  </div>
  </div>
  </div>
  </div>

  <div className="row">
  <div className="col-12">
  <div className="show-order-area dashboard">
  <div className="sales-view-area">
  <div className="row">
  <div class="col-7">
  <button onClick={(e) => handleGenerateDeliveryNotePDF(e, orderId)}>Generate PDF</button>
  
  </div>
  
  </div>
  
  <div className="row page-header" style={{paddingTop: '10px'}}>
  <div className="col-7">
  <p>
  <span>{orderDetails.company}</span><br />{orderDetails.address}<br />
  {orderDetails.city}, {orderDetails.postal_code}, {orderDetails.country}<br />
  
  </p>
  <p>
  <span>Tel : {orderDetails.phone}</span>
  </p>
  <p>
  <span>Email : {orderDetails.email}</span>
  </p>
  <p>
  <span>Customer A/C Number: {orderDetails.account_no}</span>
  </p>
  </div>
  <div className="col-5">
  <ul>
  <li><span>Order :</span>  INV{orderDetails.reference_no}</li>
  <li><span>Order Date :</span> {orderDetails.date}</li>
  <li><span>Sale Status:</span> {orderDetails.sale_status}</li>
  <li><span>Route Number :</span> {orderDetails.route_number}</li>
  <li><span>Payment Status :</span> {orderDetails.payment_status}</li>
  </ul>
  </div>
  </div>

  <div className="sales-view-table">
  <table className="table table-bordered" id="invoicePrint">
  <thead>
  <tr>
  <th style={{backgroundColor: '#e28483'}} >Description</th>
  <th style={{backgroundColor: '#e28483'}} >Quantity</th>
  <th style={{backgroundColor: '#e28483'}} >Type</th>
  <th style={{backgroundColor: '#e28483'}} >Unit Price</th>
  <th style={{backgroundColor: '#e28483'}} >VAT Amount</th>
  <th style={{backgroundColor: '#e28483'}} >Net Amount</th>
  </tr>
  </thead>
  <tbody>

  </tbody>
  <tfoot>
  <tr>
  <td colSpan="4">
  <span className="right">Total Std Goods</span>
  </td>
  <td><span>£43.84</span></td>
  </tr>
  <tr>
  <td colSpan="4">
  <span className="right">VAT Amount</span>
  </td>
  <td><span>£8.77</span></td>
  </tr>
  <tr>
  <td colSpan="4">
  <span className="right">Total Zero Goods</span>
  </td>
  <td><span>0.00</span></td>
  </tr>
  <tr>
  <td colSpan="4">
  <span className="right">Order Discount</span>
  </td>
  <td><span>0.00</span></td>
  </tr>
  <tr>
  <td colSpan="4">
  <span className="right">Total Amount</span>
  </td>
  <td><span>£52.61</span></td>
  </tr>
  </tfoot>
  </table>
  <p style ={{textAlign: 'center'}}>No claims for damages or short delivery will be considered unless made at the time of delivery of goods.These goods are not sold on a sale or return basis.</p>
  </div>
  </div>
  </div>
  </div>
  </div>

  <Footer />
  </>
  );
}

export default OrderSummaryPrint;