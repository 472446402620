import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FinishPay from '../sales/Finish-Pay';
import Calculator from './Calculator';
import IncompleteOrder from '../sales/Incomplete_Order';
import SaveExit from '../sales/Save_Exit';
import ProformaInvoice from '../sales/Proforma_Invoice';
import OpeningBalance from '../sales/Opening_Balance';
import CustomerLedger from '../sales/Customer_Ledger';
import DayClose from '../sales/Day_Close';
import { API_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import Swal from 'sweetalert2';
import config from "../common/Config";
import { Link, useNavigate } from 'react-router-dom';

import { fetchProductList, handleEposProductAdd, fetchEposSearchCustomer } from '../../services/EposServices';

const Epos = () => {


  const navigateToEpos = useNavigate();
  const [customerData, setCustomerData] = useState([]);
  const [customerInfo, setCustomerInfo] = useState([]);
  const [customeId, setCustomeId] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [companyAddress, setCompanyAddress] = useState([]);

  let parsedCreditType = [];


  // Check if creditType exists and is a valid JSON string
  if (customerDetails.credit_type) {
    try {
      parsedCreditType = JSON.parse(customerDetails.credit_type);
    } catch (error) {
      console.error("Failed to parse JSON:", error);
    }
  }


  const [productData, setProductData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [incompleteOrder, setIncompleteOrder] = useState([]);
  const [finishToPay, setFinishToPay] = useState([]);
  const accessToken = localStorage.getItem('accessToken');
  const userId = localStorage.getItem('userId');

  const [totalVal, setTotalVal] = useState({
    totalGoods: 0,
    totalGross: 0,
    totalzeroGoods: 0,
    totalTax: 0,
    orderDiscount: 0,
    grandTotal: 0,
    boxTotal: 0,
    pieceTotal: 0,

  });
  const [discountQty, setDiscountQty] = useState(0.0);
  const [salesId, setSalesId] = useState('');
  const [salesStatus, setSalesStatus] = useState('New');
  const [salesRefNo, setSalesRefNo] = useState(0);

  useEffect(() => {

  }, []);

  /*const handleCancel = (id) => {

    setTableRows(prevRows => prevRows.filter(row => row.id !== id));
  };*/

  const handleCancel = async (id, promosId, promosType) => {
    


    setTableRows(prevRows => prevRows.filter(row => row.id !== id ));

    try {
    const updatedRows = [...tableRows]; 
    const currQty = 0;

    let totalOrderQty;
    if(promosType == "combo"){
       totalOrderQty = tableRows.reduce((sum, row) => {
          // Check if row matches the condition
          if (row.promosId == promosId && row.promosType == "combo") {
              if (row.id == id) {
                  return sum + currQty;  // Add newQty for the row with the matching id
              } else {
                  return sum += row.order_qty;  // Add the order_qty for other matching rows
              }
          }else{

            return  sum; 
          }
      }, 0); 
    }else{
       totalOrderQty = 0;
    }

    for (let row of updatedRows) {
        if (row.id === id ) {
          const order_type = row.split === '0' || row.split === '2' ? 'box' : 'piece';
          const secondApiUrl = `${config.API_URL}/ws_check_Product_Promation`;
          const secondConfig = {
            headers: { 'authorization': `Bearer ${accessToken}`},
          };

          const secondFormData = new FormData();
          secondFormData.append("orderId", 0);  
          secondFormData.append("productId", id);
          secondFormData.append("order_type", order_type);
          secondFormData.append("qty", totalOrderQty); 

          // Await API call to check for promotions
          const secondResponse = await axios.post(secondApiUrl, secondFormData, secondConfig);
          const secondData = secondResponse.data.item;

          console.log("Second API response:", secondData);

          // Only apply promotion if it's valid
          if (secondData && secondData.is_promoted === "1") {
            const promotionUnitPrice = parseFloat(secondData.unit_price) || 0;
            const promotionSubtotal = parseFloat(secondData.subtotal) || 0;
            const promotionQuantity = parseFloat(secondData.quantity) || 0;

            // Check if product with the same ID already exists
            const existingProductIndex = tableRows.findIndex(row => row.id === secondData.product_id && row.is_promoted == secondData.is_promoted  );

            if(secondData){
              if (promotionQuantity === 0) {
                setTableRows(prevRows => prevRows.filter(row => secondData.product_id !== row.id)); // Remove item if quantity is 0
                return;
              }

              // If product exists, update the existing row
              if (existingProductIndex !== -1) {
                setTableRows(prevRows => prevRows.map((row, index) => {
                  if (index === existingProductIndex) {
                    const itemTax = isNaN(secondData.item_tax) || secondData.item_tax === null || secondData.item_tax === '' ? 0 : secondData.item_tax;

                    const updatedRow = {
                      ...row,
                      order_qty: promotionQuantity,
                      subtotal: promotionSubtotal.toFixed(2),
                      vat: (promotionSubtotal * formatNumber(itemTax) / 100).toFixed(2),
                      total: (promotionSubtotal + (promotionSubtotal * formatNumber(itemTax) / 100)).toFixed(2),
                    };
                    return updatedRow;
                  }
                  return row; // Keep other rows unchanged
                }));
              } else {
                // Add a new row if product doesn't exist
                const split = secondData.order_type === "box" ? 0 : 1;

                const newRow1 = {
                  code: secondData.product_code,
                  id: secondData.product_id,
                  price: promotionUnitPrice.toFixed(2),
                  piece_cost: promotionUnitPrice.toFixed(2),
                  split: split,
                  type: secondData.order_type === "box" ? "box" : "piece",
                  split_quantity: secondData.unit_quantity,
                  split_price: promotionUnitPrice.toFixed(2),
                  name: secondData.product_name,
                  size: secondData.size,
                  cost: 0,
                  rate: secondData.item_tax ? parseFloat(secondData.item_tax) : 0,
                  quantity: promotionQuantity,
                  pdtcost: promotionUnitPrice.toFixed(2),
                  order_qty: promotionQuantity,
                  vat: 0.00,
                  subtotal: 0.00,
                  total: 0.00,
                  is_promoted: secondData.is_promoted,
                };

                // Append the new row
                setTableRows(prevRows => [...prevRows, newRow1]);
              }
            }
          }
        }
    }

  } catch (error) {
    console.error("Error checking promotion:", error);
  }

  };

  // const handleQuantityChange1111 = (id, newQty) => 
  // {

  //   setTableRows(prevRows => {
  //     return prevRows.map(row => 
  //     {
  //       if (row.id === id) {
  //         const order_qty = parseFloat(newQty) || 1;
  //         const unitCost = parseFloat(row.pdtcost) || 0;
  //         const rate = parseFloat(row.rate) || 0;

  //         const subtotal = (order_qty * unitCost).toFixed(2);
  //         const vat = ((order_qty * unitCost) * rate / 100).toFixed(2);
  //         const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

  //         return {
  //           ...row,
  //           order_qty,
  //           subtotal,
  //           vat,
  //           total
  //         };
  //       }
  //       return row;





  //         // Check for product promotions
  //       const productId = row.id;
  //       const order_type= row.split === '0' || row.split === '2' ? 'box' : 'piece';
  //       const secondApiUrl = `${config.API_URL}/ws_check_Product_Promation`;

  //       const secondConfig = {
  //           headers: { 'authorization': 'Bearer ayt' },
  //       };

  //       const secondFormData = new FormData();
  //       secondFormData.append("orderId", 0);
  //       secondFormData.append("productId", productId);
  //       secondFormData.append("order_type", order_type);
  //       secondFormData.append("qty", 1);

  //       const secondResponse = await axios.post(secondApiUrl, secondFormData, secondConfig);
  //       const secondData = secondResponse.data.item;

  //       console.log("Second API response:", secondData);

  //       // Handle the promotion data
  //    //   if (secondData && secondData.success && secondData.is_promoted === "1") 
  //    //   {

  //      //   alert("ggggg")
  //           const promotionUnitPrice = parseFloat(secondData.unit_price) || 0; // Use the promoted price (0 if promotion sets it to 0)
  //           const promotionSubtotal = parseFloat(secondData.subtotal) || 0; // Use the promoted subtotal (0 if it's discounted)
  //           const promotionQuantity = parseFloat(secondData.order_qty) || 0; // Quantity after promotion (should be the same)

  //           const newRow1 = {
  //               code: secondData.product_code,
  //               id: secondData.product_id,
  //               price: promotionUnitPrice.toFixed(2),
  //               piece_cost: promotionUnitPrice.toFixed(2),
  //               split: secondData.order_type,
  //               type: secondData.order_type === "box" ? "box" : "piece",
  //               split_quantity: secondData.unit_quantity,
  //               split_price: promotionUnitPrice.toFixed(2),
  //               name: secondData.product_name,
  //               size: secondData.size,
  //               cost: 0, // Assuming promotion means no cost
  //               rate: secondData.item_tax ? parseFloat(secondData.item_tax) : 0,
  //               quantity: promotionQuantity,
  //               pdtcost: promotionUnitPrice.toFixed(2),
  //               order_qty: promotionQuantity,
  //               subtotal: promotionSubtotal.toFixed(2),
  //               vat: (promotionSubtotal * formatNumber(secondData.item_tax) / 100).toFixed(2),
  //               total: (promotionSubtotal + (promotionSubtotal * formatNumber(secondData.item_tax) / 100)).toFixed(2),
  //           };

  //           // Add the promotion row to the table
  //           setTableRows(prevRows => [...prevRows, newRow1]);
  //       //  }















  //     });
  //   });








  //   calculateTotals(tableRows);
  // };

 const handleQuantityChange = async (id, newQty, promosId, promosType) => {
  // First, update the table rows synchronously with the new quantity

  if(newQty == 0){


    newQty = 1;
  }

  setTableRows(prevRows => prevRows.map(row => {
    if (row.id === id && row.promosType == promosType) {
      

      const order_qty = parseFloat(newQty) || 0;
      const unitCost = parseFloat(row.pdtcost) || 0;
      const rate = parseFloat(row.rate) || 0;

      

      const subtotal = (order_qty * unitCost).toFixed(2);
      const vat = ((order_qty * unitCost) * rate / 100).toFixed(2);
      const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);

      return {
        ...row,
        order_qty,
        subtotal,
        vat,
        total,
      };
    }
    return row; 
  }));

  try {
    const updatedRows = [...tableRows]; 
    const currQty = parseInt(newQty);

    let totalOrderQty;
    if(promosType == "combo"){
       totalOrderQty = tableRows.reduce((sum, row) => {
          // Check if row matches the condition
          if (row.promosId == promosId && row.promosType == "combo") {
              if (row.id == id) {
                  return sum + currQty;  // Add newQty for the row with the matching id
              } else {
                  return sum += row.order_qty;  // Add the order_qty for other matching rows
              }
          }else{

            return  sum; 
          }
      }, 0); 
    }else{
       totalOrderQty = parseInt(newQty);
    }

    


    for (let row of updatedRows) {
      if (row.id === id) {
        const order_type = row.split === '0' || row.split === '2' ? '0' : '1';
        const secondApiUrl = `${config.API_URL}/ws_check_Product_Promation`;
        const secondConfig = {
          headers: { 'authorization': `Bearer ${accessToken}`},
        };

        const secondFormData = new FormData();
        secondFormData.append("orderId", 0);  
        secondFormData.append("productId", id);
        secondFormData.append("order_type", order_type);
        secondFormData.append("qty", totalOrderQty); 

        // Await API call to check for promotions
        const secondResponse = await axios.post(secondApiUrl, secondFormData, secondConfig);
        const secondData = secondResponse.data.item;

        console.log("Second API response:", secondData);

        // Only apply promotion if it's valid
        if (secondData && secondData.is_promoted === "1") {
          const promotionUnitPrice = parseFloat(secondData.unit_price) || 0;
          const promotionSubtotal = parseFloat(secondData.subtotal) || 0;
          const promotionQuantity = parseFloat(secondData.quantity) || 0;

          // Check if product with the same ID already exists
          const existingProductIndex = tableRows.findIndex(row => row.id === secondData.product_id && row.is_promoted == secondData.is_promoted  );

          if (promotionQuantity === 0) {
            setTableRows(prevRows => prevRows.filter(row => secondData.product_id !== row.id)); // Remove item if quantity is 0
            return;
          }

          // If product exists, update the existing row
          if (existingProductIndex !== -1) {
            setTableRows(prevRows => prevRows.map((row, index) => {
              if (index === existingProductIndex) {
                const itemTax = isNaN(secondData.item_tax) || secondData.item_tax === null || secondData.item_tax === '' ? 0 : secondData.item_tax;

                const updatedRow = {
                  ...row,
                  order_qty: promotionQuantity,  // Update quantity based on promotion
                  subtotal: promotionSubtotal.toFixed(2),
                  //vat: (promotionSubtotal * formatNumber(secondData.item_tax) / 100).toFixed(2),
                  //total: (promotionSubtotal + (promotionSubtotal * formatNumber(secondData.item_tax) / 100)).toFixed(2),
                  vat: (promotionSubtotal * formatNumber(itemTax) / 100).toFixed(2),
                  total: (promotionSubtotal + (promotionSubtotal * formatNumber(itemTax) / 100)).toFixed(2),
                };
                return updatedRow;
              }
              return row; // Keep other rows unchanged
            }));
          } else {
            // Add a new row if product doesn't exist
            const split = secondData.order_type === "box" ? 0 : 1;

            const newRow1 = {
              code: secondData.product_code,
              id: secondData.product_id,
              price: promotionUnitPrice.toFixed(2),
              piece_cost: promotionUnitPrice.toFixed(2),
              split: split,
              type: secondData.order_type === "box" ? "box" : "piece",
              split_quantity: secondData.unit_quantity,
              split_price: promotionUnitPrice.toFixed(2),
              name: secondData.product_name,
              size: secondData.size,
              cost: 0,
              rate: secondData.item_tax ? parseFloat(secondData.item_tax) : 0,
              quantity: promotionQuantity,
              pdtcost: promotionUnitPrice.toFixed(2),
              order_qty: promotionQuantity,
              vat: 0.00,
              subtotal: 0.00,
              total: 0.00,
              is_promoted: secondData.is_promoted,
            };

            // Append the new row
            setTableRows(prevRows => [...prevRows, newRow1]);
          }
        }
      }
    }
  } catch (error) {
    console.error("Error checking promotion:", error);
  }
};

// UseEffect to call calculateTotals after tableRows changes
useEffect(() => {
  calculateTotals(tableRows);
}, [tableRows]); // Will run calculateTotals every time tableRows changes

  const formatNumber = (num) => {
    return parseFloat(num).toFixed(2);
  };
  const handleDiscountChange = (discQty) => {

    const value = Number(discQty);

    console.log(value);
    if (isNaN(value) || value < 0 || value.length < 2) {
      setDiscountQty('');
      return;
    }

  // Proceed if the value is a valid non-negative number
  setDiscountQty(value);
}


const handleOptionChange = async (id, pdtType, promosId, promosType) => {

  var newQty = 0;
  setTableRows(prevRows => {
    return prevRows.map(row => {
      if (row.id === id && row.is_promoted === 0) {
        const pdtcost = pdtType === 'box' ? row.price : row.piece_cost;
        const order_qty = parseFloat(row.order_qty) || 0;
       // newQty = row.order_qty;
        newQty = parseInt(row.order_qty);
        
        
        const rate = parseFloat(row.rate) || 0;
        const subtotal = (order_qty * pdtcost).toFixed(2);
        const vat = ((order_qty * pdtcost) * rate / 100).toFixed(2);
        const total = (parseFloat(subtotal) + parseFloat(vat)).toFixed(2);
        const type = pdtType === 'box' ? 'box' : 'piece';
        return {
          ...row,
          type,
          pdtcost,
          subtotal,
          vat,
          total
        };
      }
      return row;
    });

  });

  const found = Array.isArray(tableRows) ? tableRows.find(element => element.id === id && element.is_promoted === 0) : false;
  
  if(found){
    
     //console.log();

     try {
      const updatedRows = [...tableRows]; 
      const currQty = parseInt(found.order_qty);
      const orderType = pdtType === 'box' || pdtType === '2' ? '0' : '1';
      

      let totalOrderQty;
      if(promosType == "combo"){
         totalOrderQty = tableRows.reduce((sum, row) => {
            
            if (row.promosId == promosId && row.promosType == "combo") {
                if (row.id == id) {
                    return sum + currQty;  // Add newQty for the row with the matching id
                } else {
                    return sum += row.order_qty;  // Add the order_qty for other matching rows
                }
            }else{

              return  sum; 
            }
        }, 0); 
      }else{
         totalOrderQty = parseInt(currQty);
      }

    
      


      for (let row of updatedRows) {
        if (row.id === id && row.is_promoted == "0") {
          const order_type = orderType;
          const secondApiUrl = `${config.API_URL}/ws_check_Product_Promation`;
          const secondConfig = {
            headers: { 'authorization': `Bearer ${accessToken}`},
          };

          const secondFormData = new FormData();
          secondFormData.append("orderId", 0);  
          secondFormData.append("productId", id);
          secondFormData.append("order_type", order_type);
          secondFormData.append("qty", totalOrderQty); 

          // Await API call to check for promotions
          const secondResponse = await axios.post(secondApiUrl, secondFormData, secondConfig);
          const secondData = secondResponse.data.item;

          console.log("Second API response:", secondData);

          // Only apply promotion if it's valid
          if (secondData && secondData.is_promoted === "1") {
            const promotionUnitPrice = parseFloat(secondData.unit_price) || 0;
            const promotionSubtotal = parseFloat(secondData.subtotal) || 0;
            const promotionQuantity = parseFloat(secondData.quantity) || 0;

            // Check if product with the same ID already exists
            const existingProductIndex = tableRows.findIndex(row => row.id === secondData.product_id && row.is_promoted == secondData.is_promoted  );

            if (promotionQuantity === 0) {
              setTableRows(prevRows => prevRows.filter(row => secondData.product_id !== row.id)); // Remove item if quantity is 0
              return;
            }

            // If product exists, update the existing row
            if (existingProductIndex !== -1) {
              setTableRows(prevRows => prevRows.map((row, index) => {
                if (index === existingProductIndex) {
                  const itemTax = isNaN(secondData.item_tax) || secondData.item_tax === null || secondData.item_tax === '' ? 0 : secondData.item_tax;

                  const updatedRow = {
                    ...row,
                    order_qty: promotionQuantity,  // Update quantity based on promotion
                    subtotal: promotionSubtotal.toFixed(2),
                    //vat: (promotionSubtotal * formatNumber(secondData.item_tax) / 100).toFixed(2),
                    //total: (promotionSubtotal + (promotionSubtotal * formatNumber(secondData.item_tax) / 100)).toFixed(2),
                    vat: (promotionSubtotal * formatNumber(itemTax) / 100).toFixed(2),
                    total: (promotionSubtotal + (promotionSubtotal * formatNumber(itemTax) / 100)).toFixed(2),
                  };
                  return updatedRow;
                }
                return row; // Keep other rows unchanged
              }));
            } else {
              // Add a new row if product doesn't exist
              const split = secondData.order_type === "box" ? 0 : 1;

              const newRow1 = {
                code: secondData.product_code,
                id: secondData.product_id,
                price: promotionUnitPrice.toFixed(2),
                piece_cost: promotionUnitPrice.toFixed(2),
                split: split,
                type: secondData.order_type === "box" ? "box" : "piece",
                split_quantity: secondData.unit_quantity,
                split_price: promotionUnitPrice.toFixed(2),
                name: secondData.product_name,
                size: secondData.size,
                cost: 0,
                rate: secondData.item_tax ? parseFloat(secondData.item_tax) : 0,
                quantity: promotionQuantity,
                pdtcost: promotionUnitPrice.toFixed(2),
                order_qty: promotionQuantity,
                vat: 0.00,
                subtotal: 0.00,
                total: 0.00,
                is_promoted: secondData.is_promoted,
              };

              // Append the new row
              setTableRows(prevRows => [...prevRows, newRow1]);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error checking promotion:", error);
    }
  }
  

  

  calculateTotals(tableRows);
};

  /* const calculateTotals = (rows) => {
 
 
     const totalGoods = rows.reduce((sum, item) => sum + (parseFloat(item.subtotal) || 0), 0);
     const totalTax = rows.reduce((sum, item) => sum + (parseFloat(item.vat) || 0), 0);
     const totalGross = (totalGoods + totalTax);
     const orderDiscount = (totalGross * (discountQty / 100)) || 0;
     const grandTotal = (totalGross - orderDiscount);
     
     const boxTotal = rows
         .filter(item => item.type === 'box') // Filter for box type
         .reduce((sum, item) => sum + (item.order_qty || 0), 0); // Sum order_qty
 
     const pieceTotal = rows
         .filter(item => item.type === 'piece') // Filter for piece type
         .reduce((sum, item) => sum + (item.order_qty || 0), 0);
 
     
     setTotalVal({
       totalGoods : totalGoods.toFixed(2),
       orderDiscount: orderDiscount,
       totalGross: totalGross.toFixed(2),
       totalTax: totalTax.toFixed(2),
       grandTotal: grandTotal.toFixed(2),
       boxTotal: boxTotal, 
       pieceTotal: pieceTotal, 
     });
   };*/

   const calculateTotals = (rows) => 
   {


    const suTotalGoods = rows.reduce((sum, item) => sum + (parseFloat(item.subtotal) || 0), 0);
    const sumTotalTax = rows.reduce((sum, item) => sum + (parseFloat(item.vat) || 0), 0);
    const goodsDiscount = (suTotalGoods * (discountQty / 100)) || 0;
    const taxDiscount = (sumTotalTax * (discountQty / 100)) || 0;
    const totalGoods = (suTotalGoods - goodsDiscount);
    const totalTax = (sumTotalTax - taxDiscount);

    // const totalGross = (totalGoods + totalTax);

    const totalGross = (suTotalGoods + sumTotalTax);

    const grandTotal = (totalGoods + totalTax);

    const orderDiscount = discountQty;

    const boxTotal = rows
      .filter(item => item.type === 'box') // Filter for box type
      .reduce((sum, item) => sum + (item.order_qty || 0), 0); // Sum order_qty

      const pieceTotal = rows
      .filter(item => item.type === 'piece') // Filter for piece type
      .reduce((sum, item) => sum + (item.order_qty || 0), 0);


      setTotalVal({
        totalGoods: suTotalGoods.toFixed(2),
        orderDiscount: orderDiscount,
        totalGross: totalGross.toFixed(2),
        totalTax: sumTotalTax.toFixed(2),
        grandTotal: grandTotal.toFixed(2),
        boxTotal: boxTotal,
        pieceTotal: pieceTotal,
      });
    };

    const openingBalance = async () => {
      const formData = new FormData();
      formData.append("user_id", userId);
      try {

        const response = await fetch(`${config.API_URL}/ws_get_opening_bal`, {
          method: 'post',
          headers: {      
            'authorization': `Bearer ${accessToken}`,       
          },
          body : formData,
        });

        if (!response.ok) {
          throw new Error(`Error: ${response.statusText}`);
        }
        const data = await response.json(); 
        //console.log('Opening balance:', data); 

        if (data && data.success) 
        { 

        } else 
        {
         openModal(); 
       }
       return data;
     } catch (error) {
      console.error('Error fetching opening balance:', error);
    }
  };

// Call the function

const openModal = () => {
  const myModal = new window.bootstrap.Modal(document.getElementById('opening_balance'));
    myModal.show(); // Show the modal
  };


  const handleCustomerInputChange = (inputValue) => {

    fetchEposSearchCustomer(inputValue, customerInfo, setCustomerInfo, setCustomerData);
  };

  const handleCustomerChange = (selectedOption,) => {

    console.log(selectedOption);

    if (selectedOption) {
      setSelectedCustomer(selectedOption);
      setCustomeId(selectedOption.value);
      fetchProductList(setProductData, selectedOption, setCustomerDetails, setCompanyAddress, selectedOption.value);
      setTableRows([]);
      setSalesStatus("New");
      setSalesRefNo(0);
    } else {
      setSelectedCustomer(null);
      setCustomeId('');

    }
  };

  const getProductListChange = (selectedOption, customeId) => {
    if (selectedOption) {
      //alert('cust'+customeId);
      fetchProductList(setProductData, selectedOption, setCustomerDetails, setCompanyAddress, customeId);
    }
  };


  const generateUniqueId = () => {
    return `id_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  };

  const handleCancelOrder = () => {
    window.location.href = config.BASE_URL + '/sales/Epos';
  }
  const handlePlaceOrder = async () => {

    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you want to Save this order?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, Save it!',
      cancelButtonText: 'Cancel',
    });
    if (result.isConfirmed) 
    {
      try {

        const body = {
          platform: "pos",
          authorization: `Bearer ${accessToken}`,
          user_id: userId,
          product_count: totalVal.boxTotal + totalVal.pieceTotal,
          customer_id: customeId,
          customer_address: "abc",
          totalAmount: totalVal.grandTotal,
          totalVAT: totalVal.totalTax,
          paymentMode: "",
          staff_note: "",
          note_for_driver: "",
          invoiceWiseDiscountPercentage: discountQty,
          invoiceWiseDiscountAmount: "",
          discountAmount: totalVal.orderDiscount,
          promotionId: "",
          promotionSubType: "",
          collect_amount: totalVal.grandTotal,
          uniqueOrderId: generateUniqueId(),
          chequeNo: "",
          chequeDate: "",
          latitude: "",
          longitude: "",
          address: "",
          product_discount: "0.0",
          status: 'Incomplete',
          sales_id:salesId,
          reference_no:salesRefNo,
          sale_details: tableRows.map(row => ({
            product_id: row.id,
            product_code: row.code,
            product_name: row.name,
            unit_price: row.pdtcost,
            quantity: row.order_qty,
            quantityType: row.type,
            productWiseDiscountPercentage: "0.0",
            productWiseDiscountAmount: "",
            subTotal: row.subtotal,
            vat: row.vat,
            isPromoted: row.is_promoted,
            promo_id: row.promosId,
            promo_type : row.promosType,
            total: row.total,
          })),
        };


        const response = await fetch(`${config.API_URL}/ws-place-order-v2_pos`, {
          method: 'POST',
          headers: {

          },
          body: JSON.stringify(body)
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        if (data.success) {

          Swal.fire({
            title: "Success!",
            text: "Order Saved successfully.",
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          }).then(() => {
            window.location.href = config.BASE_URL + '/sales/Epos';
         });

        } else {
          Swal.fire({
            title: "Failed!",
            text: "Order Saved Failed. Try Again",
            icon: "error",
            timer: 1500,
            showConfirmButton: false,
          });
        }


        // console.log(data);


      } catch (error) {
        console.error('Error:', error);

      }
    }
  };


  const handleIncompleteOrder = async () => {
    try {

      const response = await fetch(`${config.API_URL}/ws-incomplete-order`, {
        method: 'POST',
        headers: {

          'authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({})
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setIncompleteOrder(data.data);



    } catch (error) {
      console.error('Error:', error);

    }
  };


  const handleFinishToPay = async () => {
    try {

      const response = await fetch(`${config.API_URL}/ws-finish-to-pay`, {
        method: 'POST',
        headers: {

          'authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({})
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setIncompleteOrder(data.data);



    } catch (error) {
      console.error('Error:', error);

    }
  };

  useEffect(() => {


   openingBalance();


   calculateTotals(tableRows);
 }, [tableRows, discountQty]);

  return (
    <div>


  {/* Incomplete Order */}


  <Header />
  <FinishPay finishToPay={finishToPay} tableRows={tableRows} totalVal={totalVal} customeId={customeId} discountQty={discountQty} salesId={salesId} salesStatus={salesStatus} salesRefNo={salesRefNo} customerDetails={customerDetails} />


  <ProformaInvoice tableRows={tableRows} totalVal={totalVal} customerDetails={customerDetails} companyAddress={companyAddress} />
  <IncompleteOrder
  incompleteOrder={incompleteOrder}
  setTableRow={setTableRows}
  setCustomeId={setCustomeId}
  setDiscountQty={setDiscountQty}
  setSelectedCustomer={setSelectedCustomer}
  getProductListChange={getProductListChange}
  setSalesId={setSalesId}
  setSalesStatus={setSalesStatus}
  setSalesRefNo={setSalesRefNo}

  setIncompleteOrder={setIncompleteOrder}

  />

  <SaveExit />
  <CustomerLedger />
  <DayClose />
  <OpeningBalance />
      {/* <nav className="breadcrumbs">
  <div className="container-fluid">
  <div className="row">
  <div className="col-12 col-lg-12 col-sm-12">
  <a href="#"><i className="fa fa-home"></i> </a>
  <span className="last-crumb">Epos</span>
  </div>
  </div>
  </div>
</nav> */}
<div className="epos-container">
<section id="topbar" class="d-flex align-items-center">
<div class="container-fluid">
<div class="product-code-area epos_procd-area">
<div class="row">
<div class="col-12 col-lg-12 col-sm-12">
<ul>
<li style={{width : '25%'}}>
<div class="row">
<div class=" col-5">
<label>Custumer Name : </label>
</div>
<div class=" col-7">

<Select className='abovedd selct-dd'
isClearable
onInputChange={handleCustomerInputChange}
onChange={handleCustomerChange}
options={customerData}
value={selectedCustomer}
placeholder="Enter Customer Name"
/>
</div>
</div>


</li>
<li style={{width : '33%'}}>
<label>Product Name : </label>

<Select className='abovedd'
isClearable
onChange={(e) => handleEposProductAdd(e, tableRows, setTableRows, customeId, handleQuantityChange)}
options={productData}
placeholder="Enter Product Code/Name"
/>

</li>
<li> <span>{/*Status : {salesStatus}-{salesRefNo} */} </span></li>
</ul>
</div>
</div>
</div>
<div class="products-area">
<div class="row">
<div class="col-6 col-lg-9 col-sm-9">
<div class="productlist-area">
<div class="row">
<div class="col-12 col-sm-12 col-lg-12">
<div class="tbl-header" >
<table cellpadding="0" cellspacing="0" border="0" class="table-hover">
<thead>
<tr>
<th>No</th>
<th>Code</th>
<th>Product Detail</th>
<th>Type</th>
<th>Qty</th>
<th>Rate</th>
<th>Amount</th>
<th>VAT</th>
<th>Total</th>
<th>#</th>
</tr>
</thead>
</table>

</div>
<div class="tbl-content">
<table cellpadding="0" cellspacing="0" border="0" className='table table-striped'>
<tbody>




{tableRows.length === 0 ? (
  <tr style={{ textAlign: "center" }}>
  <td colSpan={9}>No product added</td>
  </tr>
  ) : (
  tableRows.map((row, index) => (
  <tr key={row.id}>
  <td>{index + 1}</td>
  <td>{row.code}</td>
  <td>{row.name} - {row.is_promoted}</td>
  <td>

  {row.split == 2 ? (
    <select
    onChange={(e) => handleOptionChange(row.id, e.target.value, row.promosId, row.promosType)}

    className="form-select "
    >
    <option value="box">Box</option>
    <option value="piece">Piece</option>
    </select>
    ) : (
    row.split == 0 ? 'Box' : 'Piece'
    )}

    </td>
    <td>
    {row.is_promoted == 1 ? (
      <>
      <input
      readOnly
      type="text"
      value={row.order_qty}
      min="1"
      onChange={(e) => handleQuantityChange(row.id, e.target.value, row.promosId, row.promosType)}       
      className="form-control order-qty text-center"
      />
      {/*(P) */}
      </>
      ) : (
      <input
      type="text"
      value={row.order_qty}
      min="1"
      onChange={(e) => handleQuantityChange(row.id, e.target.value, row.promosId, row.promosType)}
      onFocus={(e) => e.target.select()}
      className="form-control order-qty text-center"
      />
      )}
      </td>

      <td className="text-end">

      <input
      type="text"
      value={row.pdtcost}
      min="0"

      className="form-control order-cost text-center price-txt"

      />
      </td>


      <td>{row.subtotal}</td>
      <td className="text-end">{row.vat}</td>
      <td>{row.total}</td>
      <td >
      {row.is_promoted == 0 ? (
        <button
        className="btn btn-danger cancel-btn"
        onClick={() => handleCancel(row.id, row.promosId, row.promosType)}

        style={{ padding: '0px', fontSize: '19px', color: '#dc3545', background: 'none', border: 'none' }}
        >
        <i class="fa fa-trash-o" ></i>

        </button>
        ) : (
          <>
          <div style={{position : 'relative', minHeight: '40px',display: 'block'}}>
            <img src={`${config.BASE_URL}/assets/img/orange_ribbon.png`} style={{width: '40px',transform: 'rotate(90deg)', height: '15px', position: 'absolute',right: '-4px', top : '12px'}} alt="PRMO" />
            <span style={{transform: 'rotate(90deg)',display: 'block', color: '#fff',fontSize: '9px',letterSpacing: '0.9px', padding : '12px', marginRight : '2px'}}>P</span>
          </div>
          </>
        )}
      </td>
      </tr>
      ))
      )}
      </tbody>
      </table>
      </div>
      </div>
      </div>
      <div class="pos-footer">
      <div class="row">
      <div class="col-12 col-lg-12 col-sm-12">
      <ul>
      <li>
      <label class="col-form-label">Box</label>
      <span>{totalVal.boxTotal}</span> </li>
      <li>
      <label class="col-form-label">Piece</label>
      <span>{totalVal.pieceTotal}</span> </li>
      <li>
      <label class="col-form-label">Goods Total</label>
      <span>{totalVal.totalGoods}</span> </li>
      <li>
      <label class="col-form-label">Vat</label>
      <span>{totalVal.totalTax}</span> </li>
      <li>
      <label class="col-form-label">Gross</label>
      <span>{totalVal.totalGross}</span> </li>
      <li>
      <label class="col-form-label">Disc %</label>
      <input 
      type="text" 
      minlength="0" 
      maxlength="2"   
      class="form-control no-arrows" 
      onChange={(e) => handleDiscountChange(e.target.value)} 
      value={discountQty}
      />

      </li>
      <li>
      <label class="col-form-label">Grand Total</label>
      <span>{totalVal.grandTotal}</span> </li>
      </ul>
      </div>
      </div>
      </div>
      </div>
      </div>
      <div class="col-6 col-lg-3 col-sm-3">
      <div class="right-button-area">
      <div class="customer-detail">
      {parsedCreditType.length === 0 && (
      <h3><span>Customer Name</span></h3>
        )}
      <h3><span></span>{customerDetails.company}</h3>

      <ul>

      <li>
        <p><span>Credit Type</span></p>
      </li>
      {parsedCreditType.length > 0 ? (
        parsedCreditType
        .filter(item => item.name) // Filter out items where name is null, undefined, or empty string
        .map((item, index) => (
        <li key={index}>
      {/*   <p><span>Credit:</span> {item.name || 'N/A'}</p>*/}

    {/* Conditionally render Total Day */}
    {item.total_day && <p><span>Day</span>: {item.total_day}</p>}

  {/* Conditionally render Total Invoice */}
  {item.total_invoice && <p><span>Invoice</span>: {item.total_invoice}</p>}

{/* Conditionally render Total Amount */}
{item.total_amount && <p><span>Amount</span>: {item.total_amount}</p>}

{/* <hr />*/}
</li>
))
) : null} {/* No fallback content when empty */}

{parsedCreditType.length === 0 && (
  <>
   
    <li><span>No Credit</span></li>
  </>
)}

  </ul>


                      {/* <p><b>Used :</b> <span>2500</span> <b>Available : </b> <span>Available</span> </p>
                      <p><b>Amount Due :</b> <span>1000</span></p> */}
                      </div>
                      <div>
                    {/*  <form>*/}
                  {/* Add Keyboard / Calculator  */}
                {/* <Calculator /> */}

                {/* <table class="table-borderless">
                <tbody>
                <tr>
                <td>Goods Total</td>
                <td><input type="text" class="form-control" /></td>
                </tr>
                <tr>
                <td>Total Discount</td>
                <td><input type="text" class="form-control" /></td>
                </tr>
                <tr>
                <td>Total Net Price</td>
                <td><input type="text" class="form-control" /></td>
                </tr>
                <tr>
                <td>Total VAT</td>
                <td><input type="text" class="form-control" /></td>
                </tr>
                <tr>
                <td>Total to pay</td>
                <td><input type="text" class="form-control" /></td>
                </tr>
                </tbody>
              </table> */}

            {/*  </form>*/}
            </div>
            <div class="row">
            <div class="col-12 col-sm-12 col-lg-12">
            <ul>

            <li>

            <button type="submit" class="btn btn-primary eposbtn" data-bs-toggle="modal" data-bs-target="#Day-Close">Day Close</button>
          {/* <button type="submit" class="btn btn-primary eposbtn" data-bs-toggle="modal" data-bs-target="#Customer-ledger">Customer Ledger</button>*/}
        {/* <a href='#' className='eposbtn'>Customer Ledger</a> */}
        </li>
        <li>
      {/* <button type="submit" class="btn btn-primary eposbtn">Proforma</button> */}
      <button type="submit" className="btn btn-primary eposbtn" data-bs-toggle="modal" data-bs-target="#proforma_invoice">Proforma</button>
      </li>
      <li>
      <button type="submit" class="btn btn-primary eposbtn" data-bs-toggle="modal" data-bs-target="#verticalycentered">Finish & Pay</button>
    {/* <a href='/Finish_Pay' className='eposbtn toppad'>Finish & Pay</a> */}
    </li>
    <li>
    <button type="submit" class="btn btn-primary eposbtn" data-bs-toggle="modal" data-bs-target="#incomplete-order" onClick={handleIncompleteOrder}>Incomplete Order</button>
  {/* <a href='/Incomplete_Order' className='eposbtn'>Incomplete Order</a> */}
  </li>
  <li>
  <button type="submit" class="btn btn-primary eposbtn" onClick={handlePlaceOrder} >Save & Exit</button>
{/* <a href='#' className='eposbtn toppad' data-bs-toggle="modal" data-bs-target="#Save-exit">Save & Exit</a> */}
</li>
<li>
<button type="submit" class="btn btn-primary eposbtn" onClick={handleCancelOrder}>Cancel & Exit</button>
{/* <a href='#' className='eposbtn toppad'>Cancel & Exit</a> */}
</li>
<li>
{/* <button type="submit" class="btn btn-primary eposbtn">Proforma</button> */}
{/*  <button type="submit" className="btn btn-primary eposbtn" data-bs-toggle="modal" data-bs-target="#opening_balance">Opening Balance</button>*/}
</li>
</ul>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</section>
</div>
<Footer />
</div>
);
};

export default Epos;
